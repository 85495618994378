import Alert from 'react-s-alert'
import {
  IMAGE,
  VIDEO,
  AUDIO,
  DOCUMENT,
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
  MAX_AUDIO_SIZE,
  MAX_DOCUMENT_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  MAX_VIDEO_SIZE_TEXT,
  MAX_AUDIO_SIZE_TEXT,
  MAX_DOCUMENT_SIZE_TEXT,
} from 'consts'
import errorHelper from 'utils/errorHelper'
import {
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  AUDIO_EXTENSIONS,
  MAX_DRAG_AND_DROP_ATTACHMENTS_ALLOWED,
  LOCAL_STORAGE_SELECTED_MEDIA_FILTERS,
  DOCUMENT_EXTENSIONS,
} from '../Calendar/consts'

export const handleMediaFileChange = ({
  acceptedFiles,
  setUploadingMedias,
  setUploadingMediasAmount,
  replacementType,
}) => {
  if (acceptedFiles.length <= MAX_DRAG_AND_DROP_ATTACHMENTS_ALLOWED) {
    const newMedias = []
    for (const file of acceptedFiles) {
      const { name, size } = file
      const ext = name.substring(name.lastIndexOf('.'))

      let TYPE = null
      let MAX_SIZE = null
      let TYPE_SIZE = null

      if (!replacementType) {
        if (IMAGE_EXTENSIONS.includes(ext.toLowerCase())) {
          TYPE = IMAGE
        } else if (VIDEO_EXTENSIONS.includes(ext.toLowerCase())) {
          TYPE = VIDEO
        } else if (AUDIO_EXTENSIONS.includes(ext.toLowerCase())) {
          TYPE = AUDIO
        } else if (DOCUMENT_EXTENSIONS.includes(ext.toLowerCase())) {
          TYPE = DOCUMENT
        }
      } else {
        TYPE = replacementType
      }

      if (TYPE === IMAGE) {
        MAX_SIZE = MAX_IMAGE_SIZE
        TYPE_SIZE = MAX_IMAGE_SIZE_TEXT
      } else if (TYPE === VIDEO) {
        MAX_SIZE = MAX_VIDEO_SIZE
        TYPE_SIZE = MAX_VIDEO_SIZE_TEXT
      } else if (TYPE === AUDIO) {
        MAX_SIZE = MAX_AUDIO_SIZE
        TYPE_SIZE = MAX_AUDIO_SIZE_TEXT
      } else if (TYPE === DOCUMENT) {
        MAX_SIZE = MAX_DOCUMENT_SIZE
        TYPE_SIZE = MAX_DOCUMENT_SIZE_TEXT
      }

      if (TYPE) {
        if (size <= MAX_SIZE) {
          const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
          newMedias.push({
            tempId: uploadingId,
            id: uploadingId,
            uploadingId,
            file,
            type: TYPE,
            replacement_type: replacementType,
          })
        } else {
          Alert.error(`The size of the ${TYPE} must be less than ${TYPE_SIZE}!`, {
            timeout: 5000,
          })
        }
      } else {
        if (TYPE === IMAGE) {
          Alert.error(`Only ${IMAGE_EXTENSIONS.join(', ')} ${IMAGE} files allowed!`, { timeout: 5000 })
        } else if (TYPE === VIDEO) {
          Alert.error(`Only ${VIDEO_EXTENSIONS.join(', ')} ${VIDEO} files allowed!`, { timeout: 5000 })
        } else if (TYPE === AUDIO) {
          Alert.error(`Only ${AUDIO_EXTENSIONS.join(', ')} ${AUDIO} files allowed!`, { timeout: 5000 })
        } else if (TYPE === DOCUMENT) {
          Alert.error(`Only ${DOCUMENT_EXTENSIONS.join(', ')} ${DOCUMENT} files allowed!`, { timeout: 5000 })
        }
      }
    }
    if (newMedias.length) {
      if (setUploadingMediasAmount) {
        setUploadingMediasAmount(newMedias.length)
      }
      setUploadingMedias([...newMedias])
    }
  } else {
    Alert.error(`Please limit you upload to ${MAX_DRAG_AND_DROP_ATTACHMENTS_ALLOWED} files!`)
  }
}

export const saveSelectedMediaFiltersToStorage = ({ filters, id }) => {
  localStorage.setItem(`${LOCAL_STORAGE_SELECTED_MEDIA_FILTERS}_${id}`, JSON.stringify({ filters }))
}

export const getSelectedMediaFiltersFromStorage = ({ id }) => {
  let selectedMediaFiltersFromStorage
  try {
    selectedMediaFiltersFromStorage = localStorage.getItem(`${LOCAL_STORAGE_SELECTED_MEDIA_FILTERS}_${id}`)
    if (selectedMediaFiltersFromStorage) {
      selectedMediaFiltersFromStorage = JSON.parse(selectedMediaFiltersFromStorage) || {}
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Media/helpers',
      functionName: 'getSelectedMediaFiltersFromStorage',
      sendError: false,
      showAlert: false,
    })
  }
  return { selectedMediaFiltersFromStorage }
}
